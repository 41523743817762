<template>
  <van-field v-model="currentText" v-bind="{ ...$attrs, ...elProps }" :required="required" :rules="rules" @click="handleEvent('click')">
    <template v-if="config.append" slot="extra">
      {{ config.append }}
    </template>
    <template #right-icon>
      <slot name="right-icon"></slot>
    </template>
  </van-field>
</template>
<script>
import { request } from "@/api/service";
import mixins from "./mixins";
export default {
  name: "HTextSelect",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
      }
    },
    "config.options": {
      handler(newVal, oldVal) {
        this.options = newVal;
      }
    }
  },
  data() {
    return {
      pageData: {
        show: false
      },
      val: null,
      options: []
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          show: true,
          readonly: true,
          disabled: false,
          options: [],
          valueKey: "value",
          labelKey: "label",
          clearable: true,
          isLink: true,
          blurEvent: null,
          changeEvent: null,
          placeholder: "请选择"
        },
        this.config
      );
    },
    currentText: {
      get() {
        if (!(this.val && this.options && this.options.length > 0)) {
          return null;
        }
        const textOption = this.options.filter(item => {
          return item[this.elProps.valueKey] === this.val;
        });
        if (textOption && textOption.length > 0) {
          return textOption[0][this.elProps.labelKey];
        } else {
          return null;
        }
      },
      // eslint-disable-next-line no-empty-function
      set() {}
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.val = this.config.val;
      if (this.config.dictCode) {
        this.$set(this, "options", this.pageContext.dictMap[this.config.dictCode]);
      } else if (this.config.apiCode) {
        request({ url: this.config.apiCode, method: this.config.apiMethod || "get" }).then(res => {
          this.$set(this, "options", res || []);
        });
      }
    },
    handleEvent(eventType) {
      const event = this.config[eventType + "Event"];
      if (event) {
        if (typeof event === "string" || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this);
        } else if (event instanceof Function) {
          event(this);
        }
      }
    }
  }
};
</script>

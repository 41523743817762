<template>
  <div class="container">
    <div class="in-mask" @click="clickMask"></div>
    <div class="plate-no-cls" @click="showWrap">
      <van-field v-if="elProps.show" v-model="val" v-bind="{ ...$attrs, ...elProps }" :required="required" :rules="rules" @focus="noBomBox" @input="handleEvent('input')" @blur="handleEvent('blur')" @change="handleEvent('change')">
        <template #right-icon>
          <div v-if="elProps.append || elProps.appendNewCar">
            <span v-if="elProps.append">{{ elProps.append }}</span>
            <span v-if="elProps.appendNewCar" class="newCar">
              <van-checkbox v-model="newCar" disabled shape="square" rel checked-color="#57ccc2" @click.stop="handleNewCar">无</van-checkbox>
            </span>
          </div>
          <slot name="right-icon"></slot>
        </template>
        <template #extra>
          <slot name="extra"></slot>
        </template>
      </van-field>
    </div>
    <transition name="hehe">
      <van-popup v-model="show" position="bottom" closeable round @close="close" :safe-area-inset-bottom="true" :style="{ height: '300px' }">
        <div style="margin: 10px 0px;font-weight: bold;text-align: center;padding: 0px 50px;">
          {{ val }}
        </div>
        <!--车牌首字-->
        <div v-if="showFirst" class="first-word-wrap">
          <div class="first-word">
            <div class="word" v-for="item in allKeyWord.province" @click="selectFirstWord(item)" :key="item.id">
              <span>{{ item }}</span>
            </div>
            <div class="in-delete" @click="deleteItem">
              <van-icon name="close" />
            </div>
          </div>
        </div>
        <!--车牌余字-->
        <div class="keyboard-wrap" v-else>
          <div class="keyboard">
            <div class="in-alphabet">
              <span v-for="(item, index) in allKeyWord.alphabet" :key="index" @click="clickKeyBoard(item)">{{ item }}</span>
              <div class="in-delete" @click="deleteItem">
                <van-icon name="close" />
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </transition>
  </div>
</template>
<script>
import mixins from "./mixins";
export default {
  name: "HCar",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          appendNewCar: false,
          label: null,
          append: null,
          show: true,
          view: false,
          size: null,
          readonly: true,
          disabled: false,
          clearable: true,
          blurEvent: null,
          changeEvent: null,
          placeholder: "请输入"
        },
        this.config
      );
    }
  },
  created() {
    this.init();
  },
  data() {
    return {
      newCar: false,
      val: null,
      selectArr: [],
      allKeyWord: {
        province: ["京", "湘", "津", "鄂", "沪", "粤", "渝", "琼", "翼", "川", "晋", "贵", "辽", "云", "吉", "陕", "黑", "甘", "苏", "青", "浙", "皖", "藏", "闽", "蒙", "赣", "桂", "鲁", "宁", "豫", "新"],
        alphabet: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "A", "B", "C", "D", "E", "F", "G", "H", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]
      },
      show: false,
      showFirst: false
    };
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
        if (this.val === "新车") {
          this.newCar = true;
        }
      }
    },
    newCar(carFlag) {
      if (!(this.config.rules && this.config.rules.length > 0)) {
        return false;
      }
      if (carFlag) {
        this.config.rules &&
          this.config.rules.forEach(item => {
            if (item.pattern) {
              item.disabled = true;
            }
          });
      } else {
        this.config.rules &&
          this.config.rules.forEach(item => {
            if (item.pattern) {
              item.disabled = false;
            }
          });
      }
    },
    selectArr(val) {
      if (val) {
        this.val = val.join("");
        this.handleEvent("input");
      }
    },
    show(val) {
      if (val) this.selectArr = this.val ? this.val.split("") : [];
    }
  },
  methods: {
    handleNewCar(e) {
      this.newCar = !this.newCar;
      if (this.newCar) {
        this.config.val = "新车";
        this.selectArr = ["新", "车"];
        this.handleEvent("input");
        return false;
      }
      this.config.val = "";
      this.selectArr = [];
      this.showFirst = true;
      this.show = true;
      this.handleEvent("input");
    },
    noBomBox() {
      document.activeElement.blur();
    },
    init() {
      this.val = this.config.val;
    },
    handleEvent(eventType) {
      if (eventType === "input") {
        if (this.val && this.config.toUpperCase) {
          this.val = this.val.toUpperCase();
        }
        this.config.val = this.val;
        this.$emit("input", this.val);
      } else {
        const event = this.config[eventType + "Event"];
        if (event) {
          if (typeof event === "string" || event instanceof String) {
            // eslint-disable-next-line no-new-func
            new Function(`return function(ctx) { ${event} }`)()(this);
          } else if (event instanceof Function) {
            event(this);
          }
        }
      }
    },
    showWrap() {
      if (this.elProps.disabled || this.config.val === "新车") {
        return;
      }
      this.show = true;
      this.showFirst = !(this.selectArr.length > 0);
    },
    selectFirstWord(item) {
      this.addSelect(item);
      this.showFirst = false;
    },
    clickKeyBoard(item) {
      this.addSelect(item);
    },
    addSelect(item) {
      // 点击自定义键盘
      if (this.selectArr.length < 8) {
        this.selectArr.push(item);
      } else {
        // this.$createToast({
        //   txt: '车牌号选择超过规定个数',
        //   type: 'txt'
        // }).show()
        // this.close()
      }
    },
    deleteItem() {
      this.selectArr.pop();
      this.showFirst = !(this.selectArr.length > 0);
    },
    close() {
      this.show = false;
    },
    clickMask() {
      // console.log('clickMask')
      this.close();
    }
  },
  mounted() {
    if (this.val) this.selectArr = this.val.split("");
  }
};
</script>
<style lang="scss" scoped>
.newCar ::v-deep {
  .van-checkbox__icon--disabled .van-icon {
    background-color: #fff;
  }
  .van-checkbox__icon--checked .van-icon {
    color: #fff;
    border-color: #57ccc2;
    background-color: #57ccc2;
  }
  .van-checkbox__label--disabled {
    color: #323233;
  }
}
.container {
  padding: 0px;
  font-size: 16px;
  .in-mask {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;

    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0);
  }
  .first-word-wrap {
    // height: 9.4rem;
    background-color: #d2d5db;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .first-word {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      &::after {
        //重要
        width: 41px;
        content: "";
      }
      .word {
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
        text-align: center;
        margin: 5px;
        span {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 100%;
          height: 100%;
          background-color: #fff;
          color: #000;
          // border: 1px solid #fff;
          border-radius: 0.125rem;
        }
      }
    }
  }
  .keyboard-wrap {
    background-color: #d2d5db;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    .keyboard {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .in-alphabet {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        &::after {
          //重要
          width: 174px;
          content: "";
        }
        span {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
          background-color: #fff;
          border-radius: 5px;
          margin: 5px;
          &:active {
            background-color: #e4e4e4;
          }
          &.bordernone {
            border: none;
            box-shadow: none;
            background-color: #d2d5db;
            &:active {
              background-color: #d2d5db;
            }
          }
          &.delete {
            background-color: #465266;
          }
          // &:last-child {
          //   flex: 1;
          // }
        }
      }
    }
  }

  .in-close {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
    background-color: #fff;
    border-radius: 5px;
    margin: 5px;
    &:active {
      background-color: #e4e4e4;
    }
    &.bordernone {
      border: none;
      box-shadow: none;
      background-color: #d2d5db;
      &:active {
        background-color: #d2d5db;
      }
    }
    &.delete {
      background-color: #465266;
    }
    &:last-child {
      flex: 1;
    }
  }

  .in-delete {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.35);
    background-color: #fff;
    border-radius: 5px;
    margin: 5px;

    &:active {
      background-color: #e4e4e4;
    }
    &.bordernone {
      border: none;
      box-shadow: none;
      background-color: #d2d5db;
      &:active {
        background-color: #d2d5db;
      }
    }
    &.delete {
      background-color: #465266;
    }
  }
}

/*淡入淡出*/
.hehe-enter,
.hehe-leave-to {
  opacity: 0;
}
.hehe-enter-to,
.hehe-leave {
  opacity: 1;
}
.hehe-enter-active,
.hehe-leave-active {
  transition: all 0.5s;
}
::v-deep .van-checkbox {
  display: flex !important;
}
::v-deep .van-field__label::after {
  position: absolute;
  box-sizing: border-box;
  content: " ";
  pointer-events: none;
  right: 16px;
  bottom: 0;
  left: 16px;
  border-bottom: 1px solid #ebedf0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
</style>

<template>
  <div class="date-select">
    <van-field
      v-model="currentDateFormat"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :readonly="true"
      :disabled="elProps.disabled"
      :required="required"
      :rules="rules"
      :inputAlign="elProps.inputAlign"
      :labelWidth="elProps.labelWidth"
      is-link
      @click="openPopup"
    >
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          :type="elProps.type"
          :title="elProps.label"
          :min-date="elProps.minDate || pageData.minDate"
          :max-date="elProps.maxDate || pageData.maxDate"
          :formatter="formatter"
          :filter="elProps.filter"
          @change="handleEvent('popupChange')"
          @confirm="handleEvent('confirm')"
          @cancel="handleEvent('cancel')"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { isArray } from "lodash";
import mixins from "./mixins";
export default {
  name: "HDatePicker",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
      }
    },
    "config.minDate": {
      handler(newVal, oldVal) {
        this.elProps.minDate = newVal;
      }
    },
    "config.maxDate": {
      handler(newVal, oldVal) {
        this.elProps.maxDate = newVal;
      }
    }
  },
  data() {
    return {
      pageData: {
        show: false,
        minDate: dayjs()
          .subtract(100, "year")
          .toDate(),
        maxDate: dayjs()
          .add(20, "year")
          .toDate()
      },
      val: null,
      currentDate: new Date()
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          label: null,
          show: true,
          readonly: false,
          disabled: false,
          type: "date", // datetime year-month  month-day time
          format: "YYYY-MM-DD",
          placeholder: "请选择",
          formatter: true,
          minDate: dayjs()
            .subtract(100, "year")
            .toDate(),
          maxDate: dayjs()
            .add(20, "year")
            .toDate(),
          changeEvent: null,
          filter: null,
        },
        this.config
      );
    },
    currentDateFormat: {
      get() {
        if (this.val) {
          return dayjs(this.val).format(this.elProps.format);
        }
        return null;
      },
      // eslint-disable-next-line no-empty-function
      set() {}
    }
  },
  created() {
    this.init();
  },
  methods: {
    dayjs,
    init() {
      this.val = this.config.val;
      if (this.val) {
        this.currentDate = dayjs(this.val).toDate();
      }
      if (this.config.minOpt && isArray(this.config.minOpt)) {
        let dayOpt = dayjs();
        this.config.minOpt.forEach(item => {
          dayOpt = dayOpt[item.opt](item.value, item.unit);
        });
        this.config.minDate = dayOpt.toDate();
      }
      if (this.config.maxOpt && isArray(this.config.maxOpt)) {
        let dayOpt = dayjs();
        this.config.maxOpt.forEach(item => {
          dayOpt = dayOpt[item.opt](item.value, item.unit);
        });
        this.config.maxDate = dayOpt.toDate();
      }
    },
    handleEvent(eventType) {
      if (eventType === "confirm") {
        this.pageData.show = false;
        if (this.currentDate) {
          this.val = this.currentDate.getTime();
        }
        this.config.val = this.val;
        this.$emit("input", this.val);
      } else if (eventType === "cancel") {
        this.pageData.show = false;
      }
      const event = this.config[eventType + "Event"];
      if (event) {
        if (typeof event === "string" || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this);
        } else if (event instanceof Function) {
          event(this);
        }
      }
    },
    openPopup() {
      if (this.elProps.disabled || this.elProps.readonly) {
        return false;
      }
      this.pageData.show = true;
      if (this.val) {
        this.currentDate = dayjs(this.val).toDate();
      }
    },
    formatter(type, val) {
      if (!this.elProps.formatter) {
        return val;
      }
      if (type === "year") {
        return val + "年";
      }
      if (type === "month") {
        return val + "月";
      }
      if (type === "day") {
        return val + "日";
      }
      if (type === "hour") {
        return val + "时";
      }
      if (type === "minute") {
        return val + "分";
      }
      return val;
    }
  }
};
</script>
<style lang="scss" scoped>
.date-select ::v-deep {
  .van-overlay {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
}
</style>

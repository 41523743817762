<template>
  <div>
    <van-field
      v-model="currentText"
      :label="elProps.label"
      :placeholder="elProps.placeholder"
      :disabled="elProps.disabled"
      :readonly="elProps.readonly"
      :required="required"
      :rules="rules"
      :inputAlign="elProps.inputAlign"
      is-link
      @click="openPopup"
    >
      <template #right-icon>
        <slot name="right-icon"></slot>
      </template>
    </van-field>
    <div>
      <van-popup v-model="pageData.show" round position="bottom">
        <van-picker ref="picker" show-toolbar :title="elProps.label" :columns="options" :value-key="elProps.labelKey" @confirm="handleConfirm" @cancel="handleCancel"> </van-picker>
      </van-popup>
    </div>
  </div>
</template>
<script>
import { request } from "@/api/service";
import { findIndex, isArray, get, template } from "lodash";
import mixins from "./mixins";
export default {
  name: "HSelectApi",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    pageForm: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
        if (this.config.relName && this.form) {
          this.form[this.config.relName] = this.currentText;
        }
      }
    },
    "config.options": {
      handler(newVal, oldVal) {
        this.options = newVal;
      }
    }
  },
  data() {
    return {
      pageData: {
        show: false
      },
      val: null,
      options: []
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          show: true,
          readonly: true,
          disabled: false,
          options: [],
          valueKey: "value",
          labelKey: "label",
          clearable: true,
          blurEvent: null,
          changeEvent: null,
          placeholder: "请选择"
        },
        this.config
      );
    },
    currentText: {
      get() {
        if (!(this.val && this.options && this.options.length > 0)) {
          return null;
        }
        const textOption = this.options.filter(item => {
          return item[this.elProps.valueKey] === this.val;
        });
        if (textOption && textOption.length > 0) {
          return textOption[0][this.elProps.labelKey];
        } else {
          return null;
        }
      },
      // eslint-disable-next-line no-empty-function
      set() {}
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.val = this.config.val;
      if (this.config.api) {
        const data = {};
        for (const item of this.config.api.relateFields) {
          let val;
          if (item.type === "path") {
            val = get(this.pageForm, item.value, null);
          } else if (item.type === "static") {
            val = item.value;
          } else {
            val = item.value;
          }
          if (item.required && !val) {
            return false;
          }
          data[item.code] = val;
        }
        const compiled = template(this.config.api.url, {
          interpolate: /{{([\s\S]+?)}}/g
        });
        request({ url: compiled(data), method: this.config.api.method || "get", data: data }).then(res => {
          if (isArray(res)) {
            this.$set(this, "options", res || []);
            this.config.options = res || [];
          }
        });
      } else {
        this.$set(this, "options", this.elProps.options);
      }
    },
    handleEvent(eventType) {
      const event = this.config[eventType + "Event"];
      if (event) {
        if (typeof event === "string" || event instanceof String) {
          // eslint-disable-next-line no-new-func
          new Function(`return function(ctx) { ${event} }`)()(this);
        } else if (event instanceof Function) {
          event(this);
        }
      }
    },
    openPopup() {
      if (this.elProps.disabled) {
        return false;
      }
      this.pageData.show = true;
      const index = findIndex(this.options, [this.elProps.valueKey, this.val]);
      this.$nextTick(() => {
        this.$refs.picker.setColumnIndex(0, index);
      });
    },
    handleConfirm(data) {
      this.pageData.show = false;
      if (!data) {
        return false;
      }
      this.val = data[this.elProps.valueKey];
      // this.currentText=data[this.elProps.labelKey]
      this.config.val = this.val;
      this.$emit("input", this.val);
      this.handleEvent("confirm");
    },
    handleCancel() {
      this.pageData.show = false;
    }
  }
};
</script>

<script>
export default {
  name: "HYRender",
  functional: true,
  props: {
    componentRef: String,
    control: String,
    config: Object,
    form: Object,
    pageForm: Object,
    on: Object
  },
  render: (h, ctx) => {
    const { componentRef, control, config, form, pageForm, on } = ctx.props;
    return h(control, { ref: componentRef, attrs: config.attrs, props: { config, form, pageForm }, on });
  }
};
</script>

<template>
  <div v-if="pageLayout.length > 0" class="h-backgroud-color_body">
    <div class="h-p-5">
      <template v-for="formItem in pageLayout">
        <div v-if="formItem.fmCode !== 'fileRecords'" :key="formItem.fmCode" class="form h-shadow-sm">
          <div class="form-title">
            <span>{{ formItem.fmName }}</span>
          </div>
          <div class="form-content">
            <HYForm :ref="formItem.fmCode + 'FormRef'" :config="formItem" :form="pageForm[formItem.fmCode]"> </HYForm>
          </div>
        </div>
      </template>
    </div>
    <div class="h-flex">
      <div class="back h-flex-1 h-text-center h-flex">
        <van-button plain block type="primary" @click="back">返回</van-button>
      </div>
      <van-button class="h-flex-1" block type="primary" @click="next">下一步</van-button>
    </div>
  </div>
</template>
<script>
import HYForm from "@/components/Form/index.vue";
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  name: "BaseClaim",
  components: { HYForm },
  provide() {
    return {
      pageContext: this
    };
  },
  inject: {
    pageForm: {
      type: Object,
      default: null
    },
    pageLayout: {
      type: Array,
      default: null
    },
    pageLayoutMap: {
      type: Object,
      default: null
    },
    PageScript: {
      type: Function,
      default: null
    },
    pageNext: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      pageData: {},
      productCode: null,
      id: null,
      applyId: null
    };
  },
  computed: {
    ...mapState("dict", ["dictMap"]),
    scriptObj() {
      const pageScriptObj = new this.PageScript();
      pageScriptObj.pageForm = this.pageForm;
      pageScriptObj.pageLayout = this.pageLayout;
      pageScriptObj.pageLayoutMap = this.pageLayoutMap;
      pageScriptObj.pageContext = this;
      return pageScriptObj;
    }
  },
  async created() {
    await this.init();
    const { id, productCode } = this.$route.query;
    this.productCode = productCode;
    this.id = id;
    window.scrollTo(0, 0);
  },
  methods: {
    dayjs,
    async init() {
      // 字典加载
      await this.$store.dispatch("dict/loadDict");
    },
    async verifyInfo() {
      for (const formItem of this.pageLayout) {
        if (formItem.fmCode === "fileRecords") {
          continue;
        }
        const formItemRef = this.$refs[`${formItem.fmCode}FormRef`];
        const validate = await formItemRef[0].validateForm();
        if (!validate) {
          this.$notify({ type: "danger", message: `请完善${formItem.fmName}` });
          return false;
        }
      }
      return true;
    },
    back() {
      this.$router.go(-1);
    },
    async next() {
      const verifyFlag = await this.verifyInfo();
      if (!verifyFlag) {
        return false;
      }
      this.pageNext({ productCode: this.productCode });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/var.scss";
.form {
  border-radius: $border-radius-lg;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: $white;
  position: relative;
  box-shadow: 0 0 10upx rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
  .form-title {
    color: $text-color;
    font-size: $font-size-lg;
    font-weight: $font-weight-bold-lg;
    line-height: $line-height-lg;
    padding: $padding-xs;
    span {
      border-left: 5px solid $primary-color;
      padding: 0 8px;
    }
  }
}
.back {
  height: 44px;
  background-color: $white;
}
</style>

<template>
  <div>
    <slot :name="`${fmCode}Header`" :form="form" :formContext="formContext" />
    <van-form ref="Form" v-bind="{ ...$attrs, ...comProps }" :model="form">
      <slot :name="`${fmCode}FormPrepend`" :form="form" :formContext="formContext" />
      <template v-for="formItem in formItems()">
        <slot :name="`${fmCode}_${formItem.prop}`" :form="form" :config="formItem">
          <HYRender
            :key="formItem.prop"
            :component-ref="formItem.prop + 'Ref'"
            :control="formItem.component"
            :config="formItem"
            :form="form"
            :pageForm="pageForm"
            :on="{
              input: val => {
                form[formItem.prop] = val;
              },
              change: val => {
                formItem.change && formItem.change(val, formItems());
              }
            }"
          />
        </slot>
      </template>
      <slot :name="`${fmCode}formAppend`" :form="form" :formContext="formContext" />
    </van-form>
    <slot :name="`${fmCode}Footer`" :form="form" :formContext="formContext" />
  </div>
</template>
<script>
import Vue from "vue";
import HYRender from "./Render";
const context = require.context("./items/", false, /.vue$/);
context.keys().forEach(key => {
  const component = context(key).default;
  Vue.component(component.name, component);
});
export default {
  name: "HYForm",
  inheritAttrs: false,
  components: { HYRender },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageForm: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageLayout: {
      type: Array,
      default: () => {
        return {};
      }
    },
    pageLayoutMap: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageOrder: {
      type: Object,
      default: () => {
        return {};
      }
    },
    pageCache: {
      type: Object,
      default: () => {
        return {};
      }
    },
    PageScript: {
      type: Function,
      default: null
    }
  },
  props: {
    fmCode: {
      type: String,
      default: null
    },
    form: {
      type: Object,
      default: () => {}
    },
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    config: {
      handler(newVal, oldVal) {
        this.init();
      }
    },
    form: {
      handler(newVal, oldVal) {
        this.formItems().forEach(formItem => {
          if (formItem.val === newVal[formItem.prop]) {
            return true;
          }
          formItem.val = newVal[formItem.prop];
        });
      },
      deep: true
    }
  },
  data() {
    return {
      formContext: this
    };
  },
  computed: {
    comProps() {
      return Object.assign(
        {
          inline: false,
          inputAlign: "right",
          errorMessageAlign: "right",
          showError: false,
          labelWidth: "60px",
          scrollToError: true,
          show: true,
          disabled: false,
          size: "mini",
          label: "",
          formItems: []
        },
        this.config
      );
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      // 初始化 model
      this.initForm();
      this.initWatch();
    },
    initForm() {
      this.formItems().forEach(formItem => {
        if (!formItem.prop) {
          return false;
        }
        if (this.form[formItem.prop]) {
          formItem.val = this.form[formItem.prop];
          return false;
        }
        this.$set(this.form, formItem.prop, formItem.val || null);
      });
    },
    initWatch() {
      this.PageScript.prototype.pageContext = this.pageContext;
      this.PageScript.prototype.pageForm = this.pageForm;
      this.PageScript.prototype.pageLayout = this.pageLayout;
      this.PageScript.prototype.pageLayoutMap = this.pageLayoutMap;
      this.PageScript.prototype.pageOrder = this.pageOrder;
      this.PageScript.prototype.pageCache = this.pageCache;
      this.config.formItems.forEach(formItem => {
        if (!formItem.prop) {
          return false;
        }
        if (!formItem.watch) {
          return false;
        }
        const watchThat = new this.PageScript();
        watchThat.form = this.form;
        watchThat.context = this;
        this.$watch(`form.${formItem.prop}`, newVal => {
          watchThat.val = newVal;
          // eslint-disable-next-line no-new-func
          const fun = new Function("pageCtx", "ctx", "val", `${formItem.watch}`);

          fun.call(watchThat, this.pageContext, this, newVal);
        });
      });
    },
    formItems() {
      return this.config.formItems.filter(item => item.show);
    },
    clearValidate() {
      this.$nextTick(() => {
        this.$refs["Form"].clearValidate();
      });
    },
    validateField(field) {
      return new Promise(resolve => {
        this.$refs["Form"]
          .validateField(field)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    validateForm() {
      return new Promise(resolve => {
        this.$refs["Form"]
          .validate()
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      });
    }
  }
};
</script>
<style lang="scss"></style>

<template>
  <van-field v-model="val" v-bind="{ ...$attrs, ...elProps }" :required="required" :rules="rules">
    <template #input>
      <slot name="input"></slot>
    </template>
    <template #right-icon>
      <slot name="right-icon"></slot>
    </template>
    <template #extra>
      <slot name="extra"></slot>
    </template>
  </van-field>
</template>
<script>
import mixins from "./mixins";
export default {
  name: "HField",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
      }
    }
  },
  data() {
    return {
      val: null
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          text: null,
          label: null,
          readonly: true,
          disabled: false,
          show: true
        },
        this.config
      );
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.val = this.config.val;
    }
  }
};
</script>

<template>
  <div>
    <HCascader :config="elProps" @input="inputEvent" @selected="selectedEvent" />
  </div>
</template>
<script>
import HCascader from "./HCascader.vue";
import areaJson from "@/assets/data/area";
export default {
  name: "HCascaderArea",
  inheritAttrs: false,
  components: { HCascader },
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    },
    form: {
      type: Object,
      default: () => {}
    },
    pageForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      options: areaJson
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          show: true,
          readonly: false,
          disabled: false,
          options: areaJson,
          valueKey: "value",
          labelKey: "label",
          childrenKey: "children",
          clearable: true,
          blurEvent: null,
          changeEvent: null,
          placeholder: "请选择",
          provinceKey: {
            code: "provinceCode",
            name: "provinceName"
          },
          cityKey: {
            code: "cityCode",
            name: "cityName"
          },
          countyKey: {
            code: "countyCode",
            name: "countyName"
          }
        },
        this.config
      );
    }
  },
  methods: {
    inputEvent(val) {
      this.$emit("input", val);
    },
    selectedEvent(options) {
      if (!(options && options.length === 3)) {
        return false;
      }
      this.form[this.elProps.provinceKey.code] = options[0].value;
      this.form[this.elProps.provinceKey.name] = options[0].label;

      this.form[this.elProps.cityKey.code] = options[1].value;
      this.form[this.elProps.cityKey.name] = options[1].label;

      this.form[this.elProps.countyKey.code] = options[2].value;
      this.form[this.elProps.countyKey.name] = options[2].label;
    }
  }
};
</script>

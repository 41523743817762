<template>
  <van-field v-model="currentText" v-bind="{ ...$attrs, ...elProps }" :required="required" :rules="rules">
    <template #right-icon>
      <span v-if="config.append">{{ config.append }}</span
      ><slot name="right-icon"></slot>
    </template>
    <template #extra>
      <slot name="extra"></slot>
    </template>
  </van-field>
</template>
<script>
import mixins from "./mixins";
export default {
  name: "HText",
  mixins: [mixins],
  inheritAttrs: false,
  inject: {
    pageContext: {
      type: Object,
      default: () => {
        return {
          pageData: {}
        };
      }
    }
  },
  props: {
    config: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    "config.val": {
      handler(newVal, oldVal) {
        this.val = newVal;
      }
    }
  },
  data() {
    return {
      val: null
    };
  },
  computed: {
    elProps() {
      return Object.assign(
        {
          val: null,
          text: null,
          label: null,
          readonly: true,
          disabled: false,
          show: true
        },
        this.config
      );
    },
    currentText: {
      get() {
        return this.elProps.text || this.val;
      },
      // eslint-disable-next-line no-empty-function
      set() {}
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.val = this.config.val;
    }
  }
};
</script>
